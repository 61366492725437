import { toggleMark } from "prosemirror-commands";
import { InputRule } from "prosemirror-inputrules";
import { MarkSpec, MarkType, Schema } from "prosemirror-model";
import { Command } from "prosemirror-state";
import { CommandFactory } from "../lib/Extension";
import markInputRule from "../lib/markInputRule";
import Mark from "./Mark";

const updateMarks =
  // @ts-expect-error - getAttrs is not in the type definitions
  (type: MarkType, attrs: { [key: string]: any }) => (state, dispatch) => {
    const { from, to } = state.selection;
    const marks = state.doc.resolve(from).marksAcross(state.doc.resolve(to));
    const tr = state.tr;

    // @ts-expect-error - getAttrs is not in the type definitions
    marks.forEach((mark) => {
      if (mark.type === type) {
        tr.removeMark(from, to, type);
      }
    });

    tr.addMark(from, to, type.create(attrs));
    dispatch?.(tr);
    return true;
  };

export default class FontSize extends Mark {
  get name() {
    return "font_size";
  }

  get schema(): MarkSpec {
    return {
      attrs: {
        size: {
          default: "16px",
        },
      },
      parseDOM: [
        {
          style: "font-size",
          // @ts-expect-error - getAttrs is not in the type definitions
          getAttrs: (value) => value,
        },
      ],
      toDOM: (mark) => ["span", { style: `font-size: ${mark?.attrs?.size}` }],
    };
  }

  inputRules({ type }: { type: MarkType }): InputRule[] {
    return [
      markInputRule(/(?:^|[^0-9])([0-9]+px)$/, type, (match) => ({
        size: match[1],
      })),
    ];
  }

  keys({ type }: { type: MarkType }): Record<string, Command> {
    return {
      "Mod-Alt-0": toggleMark(type, { size: "16px" }),
      "Mod-Alt-1": toggleMark(type, { size: "18px" }),
      "Mod-Alt-2": toggleMark(type, { size: "20px" }),
      "Mod-Alt-3": toggleMark(type, { size: "24px" }),
      "Mod-Alt-4": toggleMark(type, { size: "32px" }),
      "Mod-Alt-5": toggleMark(type, { size: "48px" }),
    };
  }

  parseMarkdown() {
    return { mark: "font_size" };
  }

  toMarkdown() {
    return {
      open: (_: any, mark: { attrs: { size: any } }) =>
        `<span style="font-size: ${mark?.attrs?.size}">`,
      close: "</span>",
      escape: false,
    };
  }

  commands({
    type,
  }: {
    type: MarkType;
    schema: Schema<any, any>;
  }): CommandFactory | Record<string, CommandFactory> | undefined {
    return (attrs = {}) => updateMarks(type, attrs);
  }
}
