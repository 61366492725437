import React from "react";

export const Heading4IconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.88889 7C5.37981 7 5.77778 7.3731 5.77778 7.83333V11.1667H9.9883V7.83333C9.9883 7.3731 10.3863 7 10.8772 7C11.3681 7 11.7661 7.3731 11.7661 7.83333V16.1667C11.7661 16.6269 11.3681 17 10.8772 17C10.3863 17 9.9883 16.6269 9.9883 16.1667V12.8333H5.77778V16.1667C5.77778 16.6269 5.37981 17 4.88889 17C4.39797 17 4 16.6269 4 16.1667V7.83333C4 7.3731 4.39797 7 4.88889 7ZM14.6199 7C15.1108 7 15.5088 7.3731 15.5088 7.83333V11.1667H18.2222V7.83333C18.2222 7.3731 18.6202 7 19.1111 7C19.602 7 20 7.3731 20 7.83333V16.1667C20 16.6269 19.602 17 19.1111 17C18.6202 17 18.2222 16.6269 18.2222 16.1667V12.8333H14.6199C14.129 12.8333 13.731 12.4602 13.731 12V7.83333C13.731 7.3731 14.129 7 14.6199 7Z" />
  </svg>
);

export const Heading5IconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.88889 7C5.37981 7 5.77778 7.3731 5.77778 7.83333V11.1667H9.9883V7.83333C9.9883 7.3731 10.3863 7 10.8772 7C11.3681 7 11.7661 7.3731 11.7661 7.83333V16.1667C11.7661 16.6269 11.3681 17 10.8772 17C10.3863 17 9.9883 16.6269 9.9883 16.1667V12.8333H5.77778V16.1667C5.77778 16.6269 5.37981 17 4.88889 17C4.39797 17 4 16.6269 4 16.1667V7.83333C4 7.3731 4.39797 7 4.88889 7ZM13.731 7.83333C13.731 7.3731 14.129 7 14.6199 7H19.1111C19.602 7 20 7.3731 20 7.83333C20 8.29357 19.602 8.66667 19.1111 8.66667H15.5088V10.125H16.0795C18.1772 10.125 20 11.5745 20 13.5625C20 15.5505 18.1772 17 16.0795 17C15.3826 17 14.7984 16.8667 14.2257 16.6011C13.7857 16.397 13.6055 15.8971 13.8232 15.4846C14.0409 15.0721 14.5741 14.9032 15.0141 15.1073C15.3396 15.2583 15.6537 15.3333 16.0795 15.3333C17.3503 15.3333 18.2222 14.4912 18.2222 13.5625C18.2222 12.6338 17.3503 11.7917 16.0795 11.7917H14.6199C14.129 11.7917 13.731 11.4186 13.731 10.9583V7.83333Z" />
  </svg>
);

export const Heading6IconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.6201 7.24717C18.9654 7.57431 18.9625 8.10194 18.6135 8.42567C17.5085 9.45086 16.7422 10.2926 16.2445 11.1584C16.2304 11.1829 16.2165 11.2075 16.2028 11.2321C16.4166 11.1892 16.6383 11.1667 16.8655 11.1667C18.5878 11.1667 20 12.4643 20 14.0833C20 15.7024 18.5878 17 16.8655 17C15.1432 17 13.731 15.7024 13.731 14.0833C13.731 12.6554 14.0424 11.476 14.6795 10.3677C15.3046 9.28031 16.2225 8.29914 17.3631 7.241C17.712 6.91727 18.2748 6.92003 18.6201 7.24717ZM15.5088 14.0833C15.5088 14.7655 16.1074 15.3333 16.8655 15.3333C17.6236 15.3333 18.2222 14.7655 18.2222 14.0833C18.2222 13.4012 17.6236 12.8333 16.8655 12.8333C16.1074 12.8333 15.5088 13.4012 15.5088 14.0833ZM4.88889 7C5.37981 7 5.77778 7.3731 5.77778 7.83333V11.1667H9.9883V7.83333C9.9883 7.3731 10.3863 7 10.8772 7C11.3681 7 11.7661 7.3731 11.7661 7.83333V16.1667C11.7661 16.6269 11.3681 17 10.8772 17C10.3863 17 9.9883 16.6269 9.9883 16.1667V12.8333H5.77778V16.1667C5.77778 16.6269 5.37981 17 4.88889 17C4.39797 17 4 16.6269 4 16.1667V7.83333C4 7.3731 4.39797 7 4.88889 7Z" />
  </svg>
);

export const ParagraphIconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.61091 5.43192C6.64236 4.51508 8.04131 4 9.5 4H19C19.5523 4 20 4.39797 20 4.88889C20 5.37981 19.5523 5.77778 19 5.77778H18V19.1111C18 19.602 17.5523 20 17 20C16.4477 20 16 19.602 16 19.1111V5.77778H14V19.1111C14 19.602 13.5523 20 13 20C12.4477 20 12 19.602 12 19.1111V13.7778H9.5C8.04131 13.7778 6.64236 13.2627 5.61091 12.3459C4.57946 11.429 4 10.1855 4 8.88889C4 7.59228 4.57946 6.34877 5.61091 5.43192ZM12 12V5.77778H9.5C8.57174 5.77778 7.6815 6.10555 7.02513 6.689C6.36875 7.27245 6 8.06377 6 8.88889C6 9.71401 6.36875 10.5053 7.02513 11.0888C7.6815 11.6722 8.57174 12 9.5 12H12Z" />
  </svg>
);

export const FontSizeIconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.40013 6C8.70314 6 8.98015 6.214 9.11566 6.55279L11.5084 12.5348C11.5136 12.5471 11.5186 12.5596 11.5234 12.5722L12.7156 15.5528C12.9132 16.0468 12.753 16.6474 12.3579 16.8944C11.9627 17.1414 11.4821 16.9412 11.2846 16.4472L10.3057 14H6.49458L5.5157 16.4472C5.31811 16.9412 4.83758 17.1414 4.4424 16.8944C4.04722 16.6474 3.88704 16.0468 4.08463 15.5528L5.27687 12.5722C5.28165 12.5596 5.28663 12.5471 5.29182 12.5348L7.68459 6.55279C7.82011 6.214 8.09711 6 8.40013 6ZM7.29457 12H9.50569L8.40013 9.23607L7.29457 12ZM17.2 11C16.8818 11 16.5766 11.158 16.3515 11.4393C16.1265 11.7206 16 12.1022 16 12.5V13H18.4V12.5C18.4 12.1022 18.2736 11.7206 18.0485 11.4393C17.8235 11.158 17.5183 11 17.2 11ZM20 12.5C20 11.5717 19.705 10.6815 19.1799 10.0251C18.6548 9.36875 17.9426 9 17.2 9C16.4574 9 15.7452 9.36875 15.2202 10.0251C14.6951 10.6815 14.4001 11.5717 14.4001 12.5V16C14.4001 16.5523 14.7582 17 15.2001 17C15.6419 17 16 16.5523 16 16V15H18.4V16C18.4 16.5523 18.7582 17 19.2 17C19.6418 17 20 16.5523 20 16V12.5Z" />
  </svg>
);

export const MagicIconSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8049 5.89538L17.5455 6.4305C17.5691 6.43688 17.5898 6.45076 17.6048 6.47003C17.6198 6.4893 17.628 6.51292 17.6283 6.5373C17.6287 6.56169 17.6211 6.58552 17.6066 6.60518C17.5922 6.62485 17.5717 6.63928 17.5483 6.64628L15.8078 7.18716C15.7901 7.19252 15.774 7.20215 15.761 7.21519C15.7479 7.22824 15.7383 7.2443 15.733 7.26196L15.1979 9.00256C15.1915 9.02609 15.1776 9.04692 15.1583 9.06189C15.1391 9.07679 15.1154 9.08507 15.091 9.08542C15.0667 9.08571 15.0428 9.07808 15.0232 9.06365C15.0035 9.04921 14.9891 9.02879 14.982 9.00544L14.4412 7.26484C14.4359 7.24718 14.4262 7.23112 14.4131 7.21807C14.4001 7.20502 14.384 7.19539 14.3664 7.19003L12.6258 6.65491C12.6022 6.64853 12.5814 6.63465 12.5665 6.61538C12.5515 6.59611 12.5433 6.57249 12.543 6.54811C12.5426 6.52372 12.5502 6.49989 12.5647 6.48023C12.5791 6.46056 12.5995 6.44614 12.6229 6.43913L14.3635 5.89825C14.3812 5.8929 14.3972 5.88327 14.4103 5.87022C14.4233 5.85718 14.433 5.84111 14.4383 5.82345L14.9734 4.08286C14.9798 4.05932 14.9937 4.03849 15.013 4.02355C15.0322 4.0086 15.0559 4.00033 15.0802 4.00001C15.1046 3.99968 15.1285 4.00732 15.1481 4.02175C15.1678 4.03617 15.1822 4.05662 15.1892 4.07998L15.7301 5.82058C15.7354 5.83823 15.7451 5.8543 15.7581 5.86735C15.7712 5.8804 15.7873 5.89002 15.8049 5.89538Z"
      fill="currentColor"
    />
    <path
      d="M11.0434 11.3847L13.791 12.1529C13.8308 12.1642 13.8657 12.1881 13.8907 12.2211C13.9156 12.2541 13.9291 12.2943 13.9291 12.3356C13.9291 12.3769 13.9156 12.4171 13.8907 12.4501C13.8657 12.4831 13.8308 12.507 13.791 12.5183L11.0405 13.2749C11.009 13.2837 10.9801 13.3005 10.957 13.3237C10.9337 13.3469 10.917 13.3757 10.9082 13.4073L10.14 16.1548C10.1288 16.1946 10.1048 16.2296 10.0718 16.2546C10.0389 16.2794 9.99867 16.2929 9.95736 16.2929C9.91599 16.2929 9.8758 16.2794 9.84282 16.2546C9.8099 16.2296 9.78596 16.1946 9.77469 16.1548L9.01802 13.4044C9.00927 13.3728 8.99249 13.344 8.96925 13.3208C8.94607 13.2976 8.91726 13.2808 8.88569 13.272L6.13811 12.5039C6.09835 12.4926 6.06334 12.4687 6.03842 12.4357C6.01349 12.4028 6 12.3626 6 12.3212C6 12.2799 6.01349 12.2397 6.03842 12.2067C6.06334 12.1737 6.09835 12.1498 6.13811 12.1385L8.88857 11.3818C8.92014 11.3731 8.94895 11.3563 8.97213 11.3331C8.99537 11.3099 9.01215 11.2811 9.02089 11.2495L9.78907 8.50197C9.80033 8.46218 9.82428 8.42721 9.85725 8.40227C9.89017 8.37733 9.93043 8.36383 9.97174 8.36383C10.013 8.36383 10.0533 8.37733 10.0863 8.40227C10.1192 8.42721 10.1431 8.46218 10.1544 8.50197L10.9111 11.2524C10.9198 11.284 10.9366 11.3128 10.9598 11.336C10.983 11.3592 11.0118 11.376 11.0434 11.3847Z"
      fill="currentColor"
    />
    <path
      d="M24.5913 19.2563C25.6184 19.3828 25.5896 20.1855 24.5942 20.2718C22.2322 20.479 19.43 20.7983 18.4518 23.3646C18.0202 24.4905 17.7325 25.8993 17.5887 27.591C17.585 27.6389 17.5723 27.6847 17.5515 27.7253C17.5306 27.766 17.5021 27.8006 17.4678 27.8269C16.855 28.2958 16.6335 27.7175 16.5731 27.2141C15.9286 21.742 14.4844 20.8357 9.2079 20.2028C9.16237 20.1974 9.11889 20.1819 9.08092 20.1575C9.04295 20.133 9.01156 20.1002 8.98926 20.0618C8.72268 19.59 8.89432 19.3176 9.50424 19.2448C15.0771 18.5802 15.7445 17.6624 16.5731 12.1759C16.6901 11.4145 16.9883 11.2812 17.4678 11.776C17.5312 11.8392 17.5718 11.9276 17.5829 12.0263C18.2791 17.4121 19.1048 18.5888 24.5913 19.2563Z"
      fill="currentColor"
    />
  </svg>
);

export const ParaphraseIconSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9981 4C9.87707 4.0005 7.84299 4.8433 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 12.5523 3.55228 13 3 13C2.44772 13 2 12.5523 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2H12.0038C14.7779 2.01044 17.4405 3.09289 19.435 5.02103L19.4471 5.03289L20 5.58579V3C20 2.44772 20.4477 2 21 2C21.5523 2 22 2.44772 22 3V8C22 8.55228 21.5523 9 21 9H16C15.4477 9 15 8.55228 15 8C15 7.44772 15.4477 7 16 7H18.5858L18.039 6.45324C16.4167 4.88785 14.2527 4.00897 11.9981 4ZM17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22L11.9962 22C9.22213 21.9896 6.55946 20.9071 4.56496 18.979L4.55289 18.9671L4 18.4142V21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21V16C2 15.4477 2.44772 15 3 15H8C8.55228 15 9 15.4477 9 16C9 16.5523 8.55228 17 8 17H5.41421L5.96095 17.5467C7.58325 19.1121 9.74722 19.991 12.0017 20C14.1228 19.9995 16.157 19.1567 17.6569 17.6569Z"
      fill="currentColor"
    />
  </svg>
);

export const RefreshCcwDotSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1C3.55228 1 4 1.44772 4 2V5.75464L5.33333 4.55464C6.77161 3.26821 8.55136 2.42538 10.4579 2.12781C12.3645 1.83025 14.3164 2.09066 16.0783 2.87763C17.8402 3.6646 19.3368 4.94453 20.3875 6.56302C21.4382 8.18151 21.9982 10.0694 22 11.9991C22.0005 12.5514 21.5532 12.9995 21.0009 13C20.4486 13.0005 20.0005 12.5532 20 12.0009C19.9986 10.4572 19.5506 8.94685 18.71 7.65206C17.8694 6.35726 16.6722 5.33332 15.2627 4.70374C13.8532 4.07417 12.2916 3.86584 10.7663 4.10389C9.24158 4.34187 7.81819 5.01578 6.66776 6.04437C6.6674 6.0447 6.66703 6.04503 6.66667 6.04536L5.60596 7H9C9.55228 7 10 7.44772 10 8C10 8.55228 9.55228 9 9 9H3C2.44772 9 2 8.55228 2 8V2C2 1.44772 2.44772 1 3 1ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM2.99909 11C3.55137 10.9995 3.9995 11.4468 4 11.9991C4.00141 13.5428 4.44942 15.0532 5.29 16.3479C6.13059 17.6427 7.32784 18.6667 8.73734 19.2963C10.1468 19.9258 11.7084 20.1342 13.2337 19.8961C14.7585 19.6581 16.1819 18.9842 17.3323 17.9556C17.3327 17.9553 17.333 17.9549 17.3333 17.9546L18.394 17H15C14.4477 17 14 16.5523 14 16C14 15.4477 14.4477 15 15 15H21C21.5523 15 22 15.4477 22 16V22C22 22.5523 21.5523 23 21 23C20.4477 23 20 22.5523 20 22V18.2454L18.669 19.4433L18.6667 19.4454C17.2284 20.7318 15.4486 21.5746 13.5421 21.8722C11.6355 22.1698 9.68356 21.9093 7.92168 21.1224C6.1598 20.3354 4.66324 19.0555 3.61251 17.437C2.56178 15.8185 2.00176 13.9306 2 12.0009C1.9995 11.4486 2.4468 11.0005 2.99909 11Z"
      fill="currentColor"
    />
  </svg>
);

export const CheckCheckSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071L1.29289 12.7071C0.902369 12.3166 0.902369 11.6834 1.29289 11.2929C1.68342 10.9024 2.31658 10.9024 2.70711 11.2929L7 15.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289ZM22.7071 9.29289C23.0976 9.68342 23.0976 10.3166 22.7071 10.7071L15.2071 18.2071C14.8166 18.5976 14.1834 18.5976 13.7929 18.2071L12.2929 16.7071C11.9024 16.3166 11.9024 15.6834 12.2929 15.2929C12.6834 14.9024 13.3166 14.9024 13.7071 15.2929L14.5 16.0858L21.2929 9.29289C21.6834 8.90237 22.3166 8.90237 22.7071 9.29289Z"
      fill="currentColor"
    />
  </svg>
);

export const ArrowDownWideNarrowSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3C7.55228 3 8 3.44772 8 4V17.5858L10.2929 15.2929C10.6834 14.9024 11.3166 14.9024 11.7071 15.2929C12.0976 15.6834 12.0976 16.3166 11.7071 16.7071L7.70711 20.7071C7.31658 21.0976 6.68342 21.0976 6.29289 20.7071L2.29289 16.7071C1.90237 16.3166 1.90237 15.6834 2.29289 15.2929C2.68342 14.9024 3.31658 14.9024 3.70711 15.2929L6 17.5858V4C6 3.44772 6.44772 3 7 3ZM10 4C10 3.44772 10.4477 3 11 3H21C21.5523 3 22 3.44772 22 4C22 4.55228 21.5523 5 21 5H11C10.4477 5 10 4.55228 10 4ZM10 8C10 7.44772 10.4477 7 11 7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H11C10.4477 9 10 8.55228 10 8ZM10 12C10 11.4477 10.4477 11 11 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H11C10.4477 13 10 12.5523 10 12Z"
      fill="currentColor"
    />
  </svg>
);

export const WrapTextSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6ZM2 12C2 11.4477 2.44772 11 3 11H18C19.0609 11 20.0783 11.4214 20.8284 12.1716C21.5786 12.9217 22 13.9391 22 15C22 16.0609 21.5786 17.0783 20.8284 17.8284C20.0783 18.5786 19.0609 19 18 19H16.4142L16.7071 19.2929C17.0976 19.6834 17.0976 20.3166 16.7071 20.7071C16.3166 21.0976 15.6834 21.0976 15.2929 20.7071L13.2929 18.7071C12.9024 18.3166 12.9024 17.6834 13.2929 17.2929L15.2929 15.2929C15.6834 14.9024 16.3166 14.9024 16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071L16.4142 17H18C18.5304 17 19.0391 16.7893 19.4142 16.4142C19.7893 16.0391 20 15.5304 20 15C20 14.4696 19.7893 13.9609 19.4142 13.5858C19.0391 13.2107 18.5304 13 18 13H3C2.44772 13 2 12.5523 2 12ZM2 18C2 17.4477 2.44772 17 3 17H10C10.5523 17 11 17.4477 11 18C11 18.5523 10.5523 19 10 19H3C2.44772 19 2 18.5523 2 18Z"
      fill="currentColor"
    />
  </svg>
);

export const ForwardSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L16.5858 13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H16.5858L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289Z"
      fill="currentColor"
    />
  </svg>
);
