import { MarkSpec } from "prosemirror-model";
import { CommandFactory } from "../lib/Extension";
import Mark from "./Mark";

export default class AskAi extends Mark {
  get name() {
    return "askAi";
  }

  get schema(): MarkSpec {
    return {
      attrs: {
        id: {},
        userId: {},
      },
      inclusive: false,
      parseDOM: [
        {
          tag: "span.askai-marker",
          getAttrs: (dom: HTMLSpanElement) => {
            const documentId = dom.getAttribute("data-askai-id");
            if (documentId && documentId !== this.editor?.props.id) {
              return false;
            }

            return {
              id: dom.getAttribute("id")?.replace("askai-", ""),
              userId: dom.getAttribute("data-user-id"),
            };
          },
        },
      ],
      toDOM: (node) => [
        "span",
        {
          class: "askai-marker",
          id: `askai-${node.attrs.id}`,
          "data-user-id": node.attrs.userId,
          "data-document-id": this.editor?.props.id,
        },
      ],
    };
  }

  commands(): CommandFactory | Record<string, CommandFactory> | undefined {
    return {
      askAi: () => (state, dispatch) => {
        const { schema } = state;
        const { tr } = state;
        const { askAi } = schema.marks;

        if (!askAi) {
          return false;
        }

        const { from, to } = state.selection;
        const mark = askAi.create();

        tr.addMark(from, to, mark);
        dispatch && dispatch(tr);
        return true;
      },
    };
  }

  toMarkdown() {
    return {
      open: "",
      close: "",
      mixable: true,
      expelEnclosingWhitespace: true,
    };
  }
}
